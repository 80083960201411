.container {
  width: auto;
  max-height: 600px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.image {
  max-width: 100%;
  max-height: 600px;
}

.arrow {
  position: absolute;
  top:45%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: rgba(121, 190, 255, 0.507);
  background-repeat: no-repeat;
  background-size: 20px;
}

.arrow:hover {
  background-color: rgba(203, 230, 255, 0.719);
}

.arrow_left {
  left: 0;
  background-image: url('../../images/left_arrow.svg');
  background-position: 35% 50%;
}

.arrow_right {
  right: 0;
  background-image: url('../../images/right_arrow.svg');
  background-position: 65% 50%;
}