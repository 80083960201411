.map {
  width: 100%;
  height: 400px;
}

.table {
  font-family: Roboto;
  margin-top: 60px;
  text-align: left;
}

.table__row {
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.table__cell {
  padding-bottom: 20px;
  padding-left: 20px;
  vertical-align: top;
  width: auto;
}

.table__textcontainer {
  margin: 0 0 15px 0;
  display: flex;
  padding: 0;
}

.table__textcontainer .table__text:first-of-type {
  font-family: 'Roboto Slab';
  font-weight: 500;
  font-size: 18px;
}

.table__textcontainer .table__text:last-of-type {
  font-family: 'Roboto';
  font-weight: 300;
}

.table__text {
  margin: 0 10px 0 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.table__textcontainer:last-of-type {
  margin: 0 0 0 0;
}

.table__accent {
  min-width: 120px;
  font-weight: 700;
  vertical-align: top;
  line-height: 1.5;
}

.external_link {
  width: 300px;
  margin-right: 30px;
  background-color: #fff;
  box-shadow: 2px 2px 10px rgb(143, 170, 201);
}

@media screen and (max-width: 768px) {
  .map {
    height: 300px;
  }

  .table__row {
    flex-direction: column;
  }

  .table__cell {
    padding-left: 0;
  }

  .table__accent {
    margin-bottom: 16px;
  }

  .external_link {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 575px) {
  .table__textcontainer {
    flex-direction: column;
  }
}