.footer__contacts {
  width: 300px;
  text-align: right;
  margin: 0;
}

@media screen and (max-width: 720px) {
  .footer__contacts {
    width: auto;
  }
}