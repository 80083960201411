.background {
  background-color: #f1f1f1;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content {
min-height: 100vh;
background: #fff;
margin-left: auto;
margin-right: auto;
padding-top: 30px;
padding-bottom: 100px;
padding-left: 30px;
padding-right: 30px;
position: relative;
border-radius: 10px;
box-shadow: 5px 5px 10px #4e697a;
}

@media screen and (min-width: calc(1147px + 30px)) {
.content {
  max-width: 1086px;
}
}

@media screen and (max-width: calc(1146px + 30px)) {
.content {
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 15px;
    padding-right: 15px;
}
}

@media screen and (max-width: 768px) {
.background {
  padding: 0;
  width: 100%;
}

.content {
  width: auto;
  margin: 0;
  padding-bottom: 50px;
  box-shadow: none;
  border-radius: unset;
}
}