.employee {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(134, 134, 134, 0.747);
  padding-bottom: 10px;
  margin: 0 0 40px;
}

.image {
  width: 150px;
  height: 150px;
  margin-right: 20px;
}

.name {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 10px;
}

.position {
  font-family: 'Roboto Slab';
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0 0 20px;
}

@media screen and (max-width: 530px) {
  .image {
    width: 120px;
    height: 120px;
  }
}