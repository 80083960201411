.exit_admin {
  background-color: #922727;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px;
  color: #fff;
  cursor: pointer;
  border: none;
  transition: background-color 300ms ease;
}

.exit_admin:hover {
  background-color: #b14747;
}

@media screen and (min-width: 768px) {
  .menu__list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .menu__list {
    padding: 0;
  }

  .menu__list_hide {
    display: none;
  }

  .menu__list_visible {
    display: flex;
    width: 320px;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    z-index: 5;
  }

  .menu__overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(64, 80, 116, 0.603);
    z-index: 4;
  }

  .menu__overlay_hide {
    display: none;
  }
}
