@font-face {
  font-family: 'Roboto Condensed';
  font-style:  normal;
  font-weight: 400;
  src: url("./fonts/roboto-condensed-v19-latin_cyrillic-regular.woff2") format("woff2");
}

@font-face {
  font-family: 'Roboto Condensed';
  font-style:  normal;
  font-weight: 700;
  src: url("./fonts/roboto-condensed-v19-latin_cyrillic-700.woff2") format("woff2");
}

@font-face {
  font-family: 'Roboto';
  font-style:  normal;
  font-weight: 100;
  src: url("./fonts/roboto-v20-latin_cyrillic-100.woff2") format("woff2");
}

@font-face {
  font-family: 'Roboto';
  font-style:  normal;
  font-weight: 300;
  src: url("./fonts/roboto-v20-latin_cyrillic-300.woff2") format("woff2");
}

@font-face {
  font-family: 'Roboto';
  font-style:  normal;
  font-weight: 500;
  src: url("./fonts/roboto-v20-latin_cyrillic-500.woff2") format("woff2");
}

@font-face {
  font-family: 'Roboto';
  font-style:  normal;
  font-weight: 700;
  src: url("./fonts/roboto-v20-latin_cyrillic-700.woff2") format("woff2");
}

@font-face {
  font-family: 'Roboto';
  font-style:  normal;
  font-weight: 900;
  src: url("./fonts/roboto-v20-latin_cyrillic-900.woff2") format("woff2");
}

@font-face {
  font-family: 'Roboto';
  font-style:  normal;
  font-weight: 400;
  src: url("./fonts/roboto-v20-latin_cyrillic-regular.woff2") format("woff2");
}

@font-face {
  font-family: 'Roboto Slab';
  font-style:  normal;
  font-weight: 100;
  src: url("./fonts/roboto-slab-v12-latin_cyrillic-100.woff2") format("woff2");
}

@font-face {
  font-family: 'Roboto Slab';
  font-style:  normal;
  font-weight: 200;
  src: url("./fonts/roboto-slab-v12-latin_cyrillic-200.woff2") format("woff2");
}

@font-face {
  font-family: 'Roboto Slab';
  font-style:  normal;
  font-weight: 300;
  src: url("./fonts/roboto-slab-v12-latin_cyrillic-300.woff2") format("woff2");
}

@font-face {
  font-family: 'Roboto Slab';
  font-style:  normal;
  font-weight: 400;
  src: url("./fonts/roboto-slab-v12-latin_cyrillic-regular.woff2") format("woff2");
}

@font-face {
  font-family: 'Roboto Slab';
  font-style:  normal;
  font-weight: 500;
  src: url("./fonts/roboto-slab-v12-latin_cyrillic-500.woff2") format("woff2");
}

@font-face {
  font-family: 'Roboto Slab';
  font-style:  normal;
  font-weight: 600;
  src: url("./fonts/roboto-slab-v12-latin_cyrillic-600.woff2") format("woff2");
}

@font-face {
  font-family: 'Roboto Slab';
  font-style:  normal;
  font-weight: 700;
  src: url("./fonts/roboto-slab-v12-latin_cyrillic-700.woff2") format("woff2");
}

@font-face {
  font-family: 'Roboto Slab';
  font-style:  normal;
  font-weight: 800;
  src: url("./fonts/roboto-slab-v12-latin_cyrillic-800.woff2") format("woff2");
}

@font-face {
  font-family: 'Roboto Slab';
  font-style:  normal;
  font-weight: 900;
  src: url("./fonts/roboto-slab-v12-latin_cyrillic-900.woff2") format("woff2");
}