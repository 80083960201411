.departmentList {
  width: auto;
  min-height: 100px;
  margin: 0 0 0 20px;
  list-style-type: none;
  padding: 0;
}

.departmentListItem {
  margin: 0 0 70px;
}

@media screen and (max-width: 530px) {
  .departmentList {
    margin: 0;
  }
}