.section {
  display: inline-block;
  width: calc((100% - 60px) / 3 * 2);
  margin-right: 30px;
}

.shortcuts {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: calc(1146px + 30px)) {
  .section {
    width: calc((100% - 40px) / 3 * 2);
    margin-right: 20px;
  }
}

@media screen and (max-width: 954px) {
  .section {
    width: calc((100% - 20px) / 2);
  }
}

@media screen and (max-width: 758px) {
  .section {
    width: 40%;
  }
}

@media screen and (max-width: 645px) {
  .section {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .shortcuts {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}