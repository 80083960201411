.section {
  width: calc((100% - 60px) / 3);
  display: inline-block;
}

.title {
  text-decoration: none;
  font-family: 'Roboto Condensed';
  font-weight: 400;
  color: #fff;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  transition: color 300ms ease;
}

.title:hover {
  color: #81badd;
}

.newslist {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 954px) {
  .section {
    width: calc((100% - 20px) / 2);
  }
}

@media screen and (max-width: 758px) {
  .section {
    width: auto;
  }
}