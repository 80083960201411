.item {
  margin-bottom: 30px;
}

.link {
  font-family: Roboto;
  line-height: 1.5;
  text-decoration: none;
  color: #0A5992;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  cursor: pointer;
}

.link:hover {
  color: rgb(97, 179, 238);
}

.text {
  font-family: Roboto;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.type {
  font-family: 'Roboto';
  font-size: 11px;
  background-color: #1a3c52;
  color: #fff;
  padding: 1px 3px;
  border-radius: 3px;
  margin-left: 5px;
  position: relative;
  top: -2px;
}

.link:hover > .type {
  background-color:rgb(97, 179, 238);
}