.side_menu {
  max-width: 400px;
  margin-right: 20px;
  background-color: rgb(255, 255, 255);
  border-right: 1px solid #4f83a8;
}

.button {
  display: none;
}

.list {
  margin: 0 10px 0 0;
  padding: 0;
  list-style-type: none;
  width: 226px;
}

@media screen and (max-width: 768px) {
  .side_menu {
    position: absolute;
    top: -60px;
    width: 280px;
    min-height: 200px;
    border-bottom-right-radius: 10px;
    border-right: none;
  }

  .side_menu_open {
    left: -15px;
    box-shadow: 5px 5px 10px #4e697a;
  }

  .side_menu_closed {
    left: -295px;
  }

  .button {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: -40px;
    background-color: #4f83a8;
    background-image: url('../../images/menu__button.svg');
    background-size: 20px 20px;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .list {
    margin: 15px;
  }

  .button {
    display: block;
  }
}