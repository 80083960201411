.add_button_container {
    width: 754px;
    margin-top: 0;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}

.add_button {
    height: 40px;
    width: 200px;
    border-radius: 10px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    background-color: #07141c;
    color: #fff;
    border: none;
    padding: 5px;
    margin: 0;
    cursor: pointer;
    transition: background-color 300ms ease;
}

.add_button:hover {
    background-color: #11374e;
}