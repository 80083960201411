.content {
  width: auto;
}

.image {
  width: 600px;
  margin-bottom: 30px;
}

.text, .list, .listItem {
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  width: 80%;
  margin-top: 0;
  margin-bottom: 20px;
}

.accent {
  font-family: 'Roboto Slab';
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: rgb(37, 65, 92);
}

.list {
  padding-left: 15px;
  margin-bottom: 25px;
}

.listItem {
  margin-top: 10px;
}

.link {
  text-decoration: none;
  color: #0A5992;
  font-weight: 400;
  cursor: pointer;
}

.link:hover {
  color: rgb(97, 179, 238);
}

@media screen and (max-width: 920px) {
  .image {
    width: 100%;
  }
  .text {
    width: 100%;
  }
}