.header {
  background-color: #07141c;
}

.content {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto Condensed', 'Helvetica', 'Arial', sans-serif;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 1146px;
}

@media screen and (max-width: calc(1146px + 30px)) {
  .header {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 680px) {
  .content {
    height: 60px;
  }
}