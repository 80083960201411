.header__logo {
  background-image: url('../../images/logo.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  outline: none;
}

@media screen and (max-width: 680px) {
  .header__logo {
    width: 45px;
    height: 45px;
  }
}