.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
}

.page {
  font-family: 'Roboto Condensed';
  font-size: 16px;
  color: #0A5992;
  background: none;
  border: none;
  cursor: pointer;
}

.page:hover {
  color: rgb(97, 179, 238);
}

.page_current {
  font-weight: 700;
  font-size: 18px;
  position: relative;
  bottom: 1px;
}